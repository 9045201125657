import axios from "axios";
const API_URL = process.env.VUE_APP_URL_API;

class InssService {

  // getInsumos(nCodOp, passo) {
  //   let params = {
  //     NCodOp: nCodOp,
  //     Passo: passo,
  //   };
  //   return axios.post(`${API_URL}/restituicao/getinsumos/`, params);
  // };

  async getListaManad() {
    return await axios.get(`${API_URL}/Inss/getListaManad/`);   
  }

  async getCliente(nCodOp, cnpj) {
    return await axios.get(`${API_URL}/Inss/getCliente`,{
      params:{
        NCodOp: nCodOp,
        Cnpj: cnpj
      }
    }); 
  }

  async getPrefeitura(nCodOp) {
    return await axios.get(`${API_URL}/Inss/getPrefeitura`,{
      params:{
        NCodOp: nCodOp,
      }
    });   
  }

  async getManadRubricas(nCodOp, cnpj){
    return await axios.get(`${API_URL}/Inss/getManadRubricas`, {
      params: {
        NCodOp: nCodOp,
        Cnpj: cnpj
      }
    }); 
  }

  async getCalculoProcessado(nCodOp, cnpj){
    return await axios.get(`${API_URL}/Inss/getCalculoProcessado`, {
      params: {
        NCodOp: nCodOp,
        Cnpj: cnpj,
      }
    }); 
  }

  async getAllPeriodos(nCodOp, cnpj){
    return await axios.get(`${API_URL}/Inss/getAllPeriodos`,{
      params: {
        NCodOp: nCodOp,
        Cnpj: cnpj,
      }
    });
  }

  async atualizaNivel(nivelId, novoNivel, cnpj, codigoRubrica, nCodOp, anoCorte){
    console.log("atualizaNivel");
    let params = {
      NivelId: nivelId,
      NovoNivel: novoNivel,
      Cnpj: cnpj,
      Cod_Rubrica: codigoRubrica,
      NCodOp: nCodOp,
      AnoCorte: anoCorte
    };
    return axios.post(`${API_URL}/Inss/atualizaNivel/`,params);
  }

  async getPeriod(nCodOp, cnpj, codRubrica){    
    return await axios.get(`${API_URL}/Inss/getPeriod`, {
      params: {
        NCodOp: nCodOp,
        Cnpj: cnpj,
        CodRubrica: codRubrica,
      }
    });  
  }

  async DeletePeriodoRubrica(nCodOp,cnpj, codigoRubrica, dt_comp){
    return await axios.get(`${API_URL}/Inss/deletePeriodoRubrica`,{
      params:{
        NCodOp: nCodOp,
        Cnpj: cnpj,
        CodRubrica: codigoRubrica,
        Dt_Comp: dt_comp,
      }
    })
  }

  async RecoverPeriod(nCodOp,cnpj, codigoRubrica, dt_comp){
      return await axios.get(`${API_URL}/Inss/recoverPeriod`,{
        params:{
          NCodOp: nCodOp,
          Cnpj: cnpj,
          CodRubrica: codigoRubrica,
          Dt_Comp: dt_comp,
        }
      })
  }

  async RecoverAllPeriodo(nCodOp,cnpj,dt_comp){
    return await axios.get(`${API_URL}/Inss/recoverAllPeriodo`,{
      params:{
        NCodOp: nCodOp,
        Cnpj: cnpj,
        Dt_Comp: dt_comp,
      }
    })
}

  async DeleteManad(nCodOp,cnpj,){
    let parans = {
      NCodOp: nCodOp,
      Cnpj: cnpj,
    };
    return axios.post(`${API_URL}/Inss/deleteManad/`, parans);
  }

  async DeleteAllPeriodo(nCodOp, cnpj, dt_comp){
    let params = {
      NCodOp: nCodOp,
      Cnpj: cnpj,
      Dt_Comp: dt_comp
    };
    console.log("params",params);
    return await axios.post(`${API_URL}/Inss/deleteAllPeriodo`, params);
  }

  async ProcessarCompensacao(nCodOp, cnpj, qntGuias, dataVencimento){
    let params = {
      NCodOp: nCodOp,
      Cnpj: cnpj,
      QntGuias: qntGuias,
      DataVencimento: dataVencimento
    };
    console.log("params",params);
    return await axios.post(`${API_URL}/Inss/processarCompensacao`, params);
  }

  async atualizaRAT(listRat){
    let parans = {
      AtualizaRAT: listRat
    } 
    return axios.post(`${API_URL}/Inss/atualizaRAT/`,parans);
  }

  async getRat(nCodOp, cnpj){
    return await axios.get(`${API_URL}/Inss/getRat`, {
      params: {
        NCodOp: nCodOp,
        Cnpj: cnpj
      }
    }); 
  }

  async checkFilesINSS(nCodOp) {
    let params = {
      NCodOp: nCodOp,
    };
    return await axios.get(`${API_URL}/Inss/verifyNCodOp/`, {params});    
  }

  async verificarDuplicidade(nCodOp, cnpj){
    console.log("verificarDuplicidade inss service");
    return await axios.get(`${API_URL}/Inss/verificaDuplicidade`,{
      params:{
        NCodOp: nCodOp,
        Cnpj: cnpj,
      }
    })
  }

  async deleteOEbyData(nCodOp, cnpj, anoCorte){
    let parans = {
      NCodOp: nCodOp,
      Cnpj: cnpj,
      Dt_Comp: anoCorte,
    };
    return axios.post(`${API_URL}/Inss/deleteOEbyData/`, parans);
  }
}

export default new InssService();